<template>
  <div>
    <b-table striped hover small
      :fields="colunas"
      :items="items"
      :sort-by.sync="sortColumn"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      @sort-changed="forceRefresh"
      :no-local-sorting="!porPagina && !forceScriptOrdenacao"
      :per-page="porPagina ? porPagina : 0"
      :current-page="paginaAtual"
      show-empty
      empty-text="Sem registro a exibir"
      empty-filtered-text="Nenhum registro encontrado"
    >
      <!-- DEFAULT -->
      <template
        v-for="(coluna, idx) in colunas"
        v-slot:[vSlotCellName(coluna.key)]="data"
      >
        <slot :name="`renderCell(${coluna.key})`" v-bind="data">
          <span :key="`col_${idx}`">
            <span v-if="coluna.render">
              {{coluna.render(data)}}
            </span>
            <span v-else>
              {{ data.value }}
            </span>
          </span>
        </slot>
      </template>
      <template #table-busy>
        <ui-loading />
      </template>
      <template
        v-for="(coluna, idx) in colunas"
        v-slot:[vSlotHeaderName(coluna.key)]="data"
      >
        <slot :name="`renderHeader(${coluna.key})`" v-bind="data">
          {{ data.label }}
        </slot>
      </template>
      <!-- OVERRIDE -->
      <template #cell(id)="data">
        {{ data.item.id }}
      </template>
      <template #cell(status)="data">
        <UiSwitch
          v-model="data.item.status"
          :readonly="true"
          :labelTrue="data.field.labelTrue ? data.field.labelTrue : 'Sim'"
          :labelFalse="data.field.labelFalse ? data.field.labelFalse : 'Não'"
        />
      </template>
      <template #cell(ativo)="data">
        <UiSwitch
          v-model="data.item.ativo"
          :readonly="true"
          :labelTrue="data.field.labelTrue ? data.field.labelTrue : 'Sim'"
          :labelFalse="data.field.labelFalse ? data.field.labelFalse : 'Não'"
        />
      </template>
      <template #cell(progresso)="data">
        <ui-progress
          class="mt-2"
          :value="data.item.progresso"
        ></ui-progress>
      </template>
      <template #cell(action)="data">
        <b-button-group size="sm">
          <slot name="actions" :data="data.item" />
        </b-button-group>
      </template>
      <template #head(action)="data">
        <div class="d-flex flex-nowrap w-100">
          <div class="text-center">{{ data.label }}</div>
          <div style="flex-grow: 1; text-align: right;">
            <b-button-group size="sm">
              <slot name="hactions" :data="data" />
            </b-button-group>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import UiSwitch from './UiSwitch.vue';

export default {
  props: {
    colunas: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Ordenação
    ordenacaoCampo: {
      type: String,
      required: false,
    },
    ordenacaoDirecao: {
      type: String,
      required: false,
      validator(value) {
        return [null, '', 'asc', 'desc'].includes(value)
      },
    },
    forceScriptOrdenacao: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Paginação por javascript
    porPagina: {
      type: Number,
      required: false,
      default: 0,
    },
    paginaAtual: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  components: { UiSwitch },
  data() {
    return {
      ordCampo: this.ordenacaoCampo || 'nome',
      ordDirecao: this.ordenacaoDirecao || 'asc',
    }
  },
  computed: {
    sortColumn: {
      get() {
        return this.ordCampo;
      },
      set(val) {
        if (val && this.ordCampo != val) {
          this.$emit('update:ordenacaoCampo', val);
          this.ordCampo = val;
        }
      }
    },
    sortDesc: {
      get() {
        return this.ordDirecao == 'desc';
      },
      set(val) {
        const sortTxt = val ? 'desc' : 'asc';
        if (this.ordDirecao != sortTxt) {
          this.$emit('update:ordenacaoDirecao', sortTxt);
          this.ordDirecao = sortTxt;
        }
      }
    }
  },
  methods: {
    forceRefresh(tbl) {
      if (tbl && tbl.sortBy) {
        this.$nextTick(() => {
          this.$emit('force-refresh');
        });
      }
    },
    vSlotCellName(tag) {
      return `cell(${tag})`
    },
    vSlotHeaderName(tag) {
      return `head(${tag})`
    },
  }
};
</script>